import React from "react"
import { motion } from "framer-motion"
import LayoutTwo from "../../components/LayoutTwo"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import constructionservices from "../../constants/constructionservices"
import constructionservicesen from "../../constants/constructionservicesen"
import SEO from "../../components/SEO"
import { useLanguage } from "../../contexts/LanguageContext"

const servicesVariant = {
  initial: {
    scale: 1,
    transition: {
      duration: 0.05,
    },
  },
  onHover: {
    scale: 1.1,
    transition: {
      duration: 0.05,
    },
  },
}

const ConstructionServices = ({
  data: {
    file: {
      childImageSharp: { fluid },
    },
  },
}) => {
  const { selectedLanguage } = useLanguage() // Use the useLanguage hook
  return (
    <LayoutTwo>
      <SEO title="Građevinske Usluge" />
      <div className="img-wrapper img-wrapper-about">
        <Image Tag="div" fluid={fluid} className="bcg bcg-about" />
        {selectedLanguage === "EN" ? (
          <h2 className="about-header visibility-filter">
            Construction services
          </h2>
        ) : (
          <h2 className="about-header visibility-filter">Građevinske Usluge</h2>
        )}
        {selectedLanguage === "EN" ? (
          <h2 className="about-header mobile-visible">Services</h2>
        ) : (
          <h2 className="about-header mobile-visible">Usluge</h2>
        )}
      </div>
      <section className="section bg-grey">
        <div className="section-center services-grid">
          {selectedLanguage === "EN"
            ? constructionservicesen.map(service => {
                const { id, icon, title, text, classStyle } = service
                return (
                  <div className="services-link" key={id}>
                    <motion.article
                      variants={servicesVariant}
                      initial="initial"
                      whileHover="onHover"
                      className="service construction-service"
                    >
                      {icon}
                      <h4>{title}</h4>
                      <div className={`underline ${classStyle}`}></div>
                      <p>{text}</p>
                    </motion.article>
                  </div>
                )
              })
            : constructionservices.map(service => {
                const { id, icon, title, text, classStyle } = service
                return (
                  <div className="services-link" key={id}>
                    <motion.article
                      variants={servicesVariant}
                      initial="initial"
                      whileHover="onHover"
                      className="service construction-service"
                    >
                      {icon}
                      <h4>{title}</h4>
                      <div className={`underline ${classStyle}`}></div>
                      <p>{text}</p>
                    </motion.article>
                  </div>
                )
              })}
        </div>
      </section>
    </LayoutTwo>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "gradj-usluge-ok.jpg" }) {
      childImageSharp {
        fluid(
          sizes: "(max-width: 300px) calc(100vw - 25px), (max-width: 600px) calc(100vw - 15px), 1300px"
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ConstructionServices
