import React from "react"
import {
  FaRoad,
  FaWater,
  FaRulerCombined,
  FaLeaf,
  FaBriefcase,
} from "react-icons/fa"
export default [
  {
    id: 1,
    icon: <FaRoad className="service-icon" />,
    title: "Roads and Motorways",
    link: "",
    classStyle: "underline-sm",
    text: `With a long tradition of almost 70 years, we have been ranked among the best companies in the region in terms of the quality of constructed roads and highways`,
  },
  {
    id: 2,
    icon: <FaRulerCombined className="service-icon" />,
    classStyle: "underline-sm",
    title: "Bridges, viaducts and other road structures",
    link: "",
    text: `In the construction of bridges, viaducts, overpasses and underpasses, we use top-quality machinery and materials, with the application of various technologies with an emphasis on highly educated staff with extensive experience`,
  },
  {
    id: 3,
    icon: <FaWater className="service-icon" />,
    title: "Construction of water management projects",
    link: "",
    classStyle: "underline-xl",
    text: `We are one of the first companies in the Republic of Croatia to participate in the construction of water projects financed by the EU. We have many years of experience in the construction of sewers, water pipelines, reservoirs, lagoons, embankments, dams, water reservoirs`,
  },
  {
    id: 4,
    icon: <FaLeaf className="service-icon" />,
    title: "Environmental protection projects",
    link: "",
    classStyle: "uunderline-md",
    text: `In recent years, our company has embraced environmental protection by obtaining the ISO 14001 certificate and installing numerous oil separators along Croatian roads. We're also proud contributors to the construction of one of Croatia's first wastewater treatment plants in Karlovac.`,
  },
  {
    id: 5,
    icon: <FaBriefcase className="service-icon" />,
    title: "Business Consulting",
    link: "",
    classStyle: "underline-sm",
    text: `We provide business consulting services in all phases of construction`,
  },
]
